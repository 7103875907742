import { FunctionComponent, useState } from 'react';
import BounceText from './components/bounceText';
import KeyInput from './components/keyInput';

import data from './data';

import './main.css';

const App: FunctionComponent = () => {
  const [showHint, setShowHint] = useState(true);
  const [gameStage, setGameStage] = useState(0);
  const [gameText, setGameText] = useState('');
  const [showGameText, setShowGameText] = useState(false);
  const [actionText, setActionText] = useState('wake up');
  const [actionTextSignal, setActionTextSignal] = useState(false);
  const [keyInputDelay, setKeyInputDelay] = useState(2.75);

  const gameStep = () => {
    if (gameStage == 1) {
      setShowHint(false);
      setKeyInputDelay(0.5);

      return setGameStage(gameStage + 1);
    }

    const virtualIndex = gameStage - 2;
    const virtualModulo = virtualIndex % 3;
    const virtualElement = Math.floor(virtualIndex / 3);

    if (virtualModulo == 0) {
      setGameText(data[virtualElement][virtualModulo]);
      setShowGameText(true);
      //console.log('Showing game text ' + data[virtualElement][virtualModulo]);
    }
    if (virtualModulo == 1) {
      setActionText(data[virtualElement][virtualModulo]);
      setActionTextSignal(!actionTextSignal);
      //console.log('Setting action text to ' + data[virtualElement][virtualModulo]);
    }
    if (virtualModulo == 2) {
      setShowGameText(false);
      //console.log('Hiding game text');
    }

    setGameStage(gameStage + 1);
  };

  return (
    <>
      <div className="container max-w-6xl mx-auto mt-4 p-8">
        {/*<h1 className="text-4xl text-red-500">GAME_STAGE: {gameStage - 1}</h1>*/}
        <BounceText className="text-6xl font-bold text-white" initialDelay={0.75} text="Wake up" />
        <BounceText
          className="text-3xl font-semibold text-slate-300 mt-2"
          initialDelay={0.8}
          text="Orion Tran"
        />
        <BounceText
          className="text-xl text-slate-400 mt-2"
          initialDelay={0.85}
          text="The following is a simulation"
        />
        <i>
          <BounceText
            className="text-xl text-slate-300"
            initialDelay={0.9}
            text="What is the boulder you push up the mountain?"
          />
          <BounceText
            className="text-xl text-slate-300"
            initialDelay={0.95}
            text="Why do you do it? Who and what contribute and hinder you in your push?"
          />
        </i>
        <BounceText
          className="text-xl text-slate-400"
          initialDelay={1.0}
          text="This simulation is nothing more than a mantra."
        />

        <BounceText
          className={
            'text-lg mt-16 mb-4 text-center text-slate-400 font-medium tracking-wide' +
            (gameStage <= 2 ? '' : ' hidden')
          }
          initialDelay={2.5}
          shown={showHint}
          text="type the text below!"
          onDone={() => gameStep()}
        />

        <BounceText
          className={
            'text-3xl font-semibold text-slate-200 mt-12 mb-4' + (gameStage > 1 ? '' : ' hidden')
          }
          text={gameText}
          shown={showGameText}
          onDone={() => gameStep()}
        />

        <KeyInput
          delay={keyInputDelay}
          text={actionText}
          signal={actionTextSignal}
          onDone={() => gameStep()}
        />
      </div>
    </>
  );
};

export default App;

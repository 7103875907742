const data: [string, string][] = [
  [
    'Opening your eyes for the first time, you come to find you have just been born. Congratulations! As a baby, you cry all day and no one can do anything about it. Your parents are always exhausted dealing with it though. As you come to realize this, you must stop crying. Stop crying to make your parents happy.',
    'stop crying'
  ],
  [
    "The day passes in a blur, not a single memory from this day remains as alas, your brain is underdeveloped. It's been a long day and it's about time you go to bed. Go to bed to make your parents happy.",
    'sleep'
  ],
  [
    "A whole year has passed and you have done what has amounted to nothing. Congratulations! Today marks your first birthday and your parents couldn't be more ecstatic. This stage of your life is still measured in months by your parents to their friends. Wake up to make your parents happy.",
    'wake up'
  ],
  [
    "It's been two whole years and you have not said your first words. Your parents are beginning to become concerned about your language development. A few months from now, however, you heard and repeated your first word, your name, Maxwell.",
    'maxwell'
  ],
  [
    "You're 5 years old now. Your whole life you haven't ever been to a pool but today your parents were bringing you and your big sister, Angeline on a little trip to the town swimming pool.",
    'wake up'
  ],
  [
    'You and your best friends are finally in the summer vacation right before middle school! Angeline offers to take you all out during the break for a few days to the family beach house. Ecstatic, you find it easier to wake up. Wake up easy today to have fun.',
    'wake up'
  ],
  [
    'Tragedy strikes and only your mother dies in a car crash leaving your father widowed and you motherless. The scary medical people diagnose you with clinical depression. You find it hard to wake up now, thus hindering the push.',
    'sleep in'
  ],
  [
    "Over the course of middle school, you learn to accept the tragedy for what it is and finally get back to school. You reason with yourself that if your mother was still here, she'd want you to go to school. For her and yourself. Wake up to meet your own expectations.",
    'wake up'
  ],
  [
    "Throughout the course of years, you have woken more times than you can fathom. After getting a job, wife, and kids of your own, you're 35 and reflect upon your life events to this point.",
    'reflect'
  ],
  [
    "You've reflected and come to realize that waking up and executing the functions of life is indeed a valid form of pushing a metaphorical boulder up a mountain and that sometimes adversity can affect the pushing in a negative way as well. You've come to realize that waking up is your mantra.",
    ''
  ]
];

export default data;

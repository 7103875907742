import { useEffect } from 'react';

const useKeyboard = (onChange: (keyDown: boolean, event: KeyboardEvent) => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      onChange(true, event);
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      onChange(false, event);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [onChange]);
};

export default useKeyboard;

import { FunctionComponent, useEffect, useState } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import useKeyboard from '../hooks/keyboard';

type KeyInputProps = {
  delay?: number;
  text: string;
  shown?: number;
  onDone?: () => void;
  signal?: boolean;
} & Partial<typeof defaultProps>;

const defaultProps = {
  delay: 0,
  shown: true,
  onDone: () => {},
  signal: false
};

const KeyInput: FunctionComponent<KeyInputProps> = (propsIn) => {
  const props = { ...defaultProps, ...propsIn };

  const letter = {
    hidden: { opacity: 0, y: 40 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 40,
        stiffness: 300
      }
    },
    typed: {
      opacity: 1,
      y: 2.5
    }
  };

  const [done, setDone] = useState(false);
  const [allowInput, setAllowInput] = useState(false);
  const [index, setIndex] = useState(0);

  useKeyboard((keyDown, event) => {
    if (!keyDown || !allowInput) return;
    const currentKey = props.text[index];
    if (event.key != currentKey) return;
    setIndex(index + 1);

    if (index != props.text.length - 1) return;

    setDone(true);

    animation
      .start((i) => ({
        opacity: 0,
        y: 30,
        transition: {
          type: 'spring',
          damping: 60,
          stiffness: 800,
          delay: i * 0.05
        }
      }))
      .then(() => props.onDone());
  });

  const animation = useAnimationControls();

  useEffect(() => {
    if (done) return;
    animation.start((i) => (i == index - 1 ? 'typed' : {}));
  }, [index]);

  useEffect(() => {
    setDone(false);
    setAllowInput(false);
    setIndex(0);
    animation
      .start((i) => ({
        opacity: 1,
        y: 0,
        transition: {
          type: 'spring',
          damping: 40,
          stiffness: 300,
          delay: props.delay + i * 0.1
        }
      }))
      .then(() => setAllowInput(true));
  }, [props.text, props.signal]);

  return (
    <>
      <motion.div className="flex justify-center items-start">
        <span className="inline-block">
          {props.text.split('').map((char, charIndex) => (
            <motion.span
              key={char + ':' + charIndex}
              className={
                'border-2 border-slate-600 rounded-md p-6 m-2 h-full text-4xl inline-block transition-colors ' +
                (index > charIndex ? 'border-green-500' : '')
              }
              initial="hidden"
              animate={animation}
              custom={charIndex}
              variants={letter}
            >
              {char.replaceAll(' ', '\u00a0')}
            </motion.span>
          ))}
        </span>
      </motion.div>
    </>
  );
};

export default KeyInput;

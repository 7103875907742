import { FunctionComponent, useEffect, useState } from 'react';
import { motion, useAnimationControls } from 'framer-motion';

type BounceTextProps = {
  className: string;
  initialDelay?: number;
  text: string;
  shown?: boolean;
  onDone?: () => void;
} & Partial<typeof defaultProps>;

const defaultProps = {
  delay: 0,
  shown: true,
  onDone: () => {}
};

const letter = {
  hidden: { opacity: 0, x: -10, y: 25, scale: 0.2, rotate: -2 },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    scale: 1,
    rotate: 0,
    transition: {
      type: 'spring',
      damping: 25,
      stiffness: 300
    }
  },
  out: {
    opacity: 0,
    y: 25,
    scale: 0.7,
    transition: {
      type: 'spring',
      damping: 25,
      stiffness: 300
    }
  }
};

const BounceText: FunctionComponent<BounceTextProps> = (propsIn) => {
  const props = { ...defaultProps, ...propsIn };

  const sentence = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: props.initialDelay,
        staggerChildren: 0.014
      }
    },
    out: {
      opacity: 1,
      transition: {
        staggerChildren: 0.00175
      }
    }
  };

  const animation = useAnimationControls();

  useEffect(() => {
    animation.start(props.shown ? 'visible' : 'out').then(() => props.onDone());
  }, [props.shown, props.text]);

  // please stop complaining that blah blah blah id blah blah blah SHUT UP!!!
  const [id] = useState(Math.random());

  return (
    <motion.p variants={sentence} animate={animation} initial="hidden" className={props.className}>
      {props.text.split(' ').map((word, index) => (
        <>
          <span key={id + word + ':' + index} className="inline-block">
            {word.split('').map((char, index) => (
              <motion.span
                key={id + char + '::' + index}
                variants={letter}
                className="inline-block"
              >
                {char}
              </motion.span>
            ))}
          </span>
          <span key={'empty:' + index}> </span>
        </>
      ))}
    </motion.p>
  );
};

export default BounceText;
